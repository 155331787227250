<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
var echarts = require("echarts");
require("echarts/theme/macarons");
import resize from "./mixins/resize";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "200px",
    },
    showBarNumber: {
      type: Number,
      default: 4,
    },
    chartData: {
      type: [Object, Array],
      required: true,
      default: () => [],
    },
    isCanClick: {
      type: Boolean,
      default: true,
    },
    unit: {
      type: String,
      default: "%",
    },
  },
  data() {
    return {
      chart: null,
      interval: null,
    };
  },
  watch: {
    // chartData: {
    //   deep: true,
    //   handler(val) {
    //     clearInterval(this.interval);
    //     this.setOptions(val);
    //   }
    // }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions(chartData = []) {
      var that = this;
      var category = [];
      chartData.map((el) => {
        category.push({ name: el.Key, value: el.Value });
      });
      var total = 100; // 数据总数
      var datas = [];
      var totalArr = [];
      category.forEach((value) => {
        datas.push(value.value);
        totalArr.push(total);
      });
      var option = {
        xAxis: {
          max: total,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        grid: {
          left: 20,
          top: 20, // 设置条形图的边距
          right: 60,
          bottom: 0,
          height: "90%",
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: category,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              inside: true,
              margin: 80,
              formatter: function(value, index) {
                return value;
              },
            },
          },
        ],
        dataZoom: [
          // 滑动条
          {
            yAxisIndex: 0, // 这里是从X轴的0刻度开始
            show: false, // 是否显示滑动条，不影响使用
            type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: that.showBarNumber, // 一次性展示6个。
          },
        ],
        series: [
          {
            // 内
            type: "bar",
            barWidth: 10,
            legendHoverLink: false,
            silent: true,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "#0049FE", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#00FFFF", // 100% 处的颜色
                  },
                ],
              },
            },
            label: {
              show: true,
              position: [0, -22],
              formatter: (params) => {
                var text = "";
                switch (params.dataIndex) {
                  case 0:
                    text = "{a| }";
                    break;
                  case 1:
                    text = "{b| }";
                    break;
                  case 2:
                    text = "{c| }";
                    break;
                  case 3:
                    text = "{d| }";
                    break;
                  case 4:
                    text = "{e| }";
                    break;
                  default:
                    text = "{g| }";
                    break;
                }
                text += "{f| " + params.data.name + "}";
                return text;
              },
              rich: {
                a: {
                  color: "#ffffff",
                  height: 18,
                  width: 20,
                  backgroundColor: {
                    image: "/img/tag/1.png",
                  },
                },
                b: {
                  color: "#ffffff",
                  height: 18,
                  width: 20,
                  backgroundColor: {
                    image: "/img/tag/2.png",
                  },
                },
                c: {
                  color: "#ffffff",
                  height: 18,
                  width: 20,
                  backgroundColor: {
                    image: "/img/tag/3.png",
                  },
                },
                d: {
                  color: "#ffffff",
                  height: 18,
                  width: 20,
                  backgroundColor: {
                    image: "/img/tag/4.png",
                  },
                },
                e: {
                  color: "#ffffff",
                  height: 18,
                  width: 20,
                  backgroundColor: {
                    image: "/img/tag/5.png",
                  },
                },
                f: {
                  color: "#FFFFFF",
                  fontSize: 14,
                  padding: [0, 0, 0, 2],
                },
                g: {
                  color: "#ffffff",
                  height: 18,
                  width: 20,
                  backgroundColor: {
                    image: "/img/tag/0.png",
                  },
                },
              },
            },
            data: category,
            z: 1,
            animationEasing: "elasticOut",
          },

          {
            type: "pictorialBar",
            symbol: "rect",
            symbolBoundingData: total,
            itemStyle: {
              color: "none",
            },
            label: {
              formatter: (params) => {
                var text = "{f| " + params.data + that.unit + "}";
                return text;
              },
              rich: {
                f: {
                  color: "#00FFFF",
                  padding: [0, 0, 0, 5],
                },
              },
              position: "right",
              distance: 0, // 向右偏移位置
              show: true,
            },
            data: datas,
            z: 0,
            animationEasing: "elasticOut",
          },
          {
            name: "外框",
            type: "bar",
            barGap: "-100%", // 设置外框粗细
            data: totalArr,
            legendHoverLink: false,
            barWidth: 10,
            itemStyle: {
              color: "#0C1A60", // 填充色
              label: {
                // 标签显示位置
                show: false,
                position: "top", // insideTop 或者横向的 insideLeft
              },
            },
            z: 0,
          },
        ],
      };
      this.chart.setOption(option);
      // if (this.isCanClick) {
      //   this.chart.on("click", this.handleClick);
      // }
      // this.interval = setInterval(() => {
      //   // 每次向后滚动一个，最后一个从头开始。
      //   if (option.dataZoom[0].endValue >= category.length) {
      //     option.dataZoom[0].endValue = that.showBarNumber;
      //     option.dataZoom[0].startValue = 0;
      //   } else {
      //     option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1;
      //     option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1;
      //   }
      //   this.chart.setOption(option);
      // }, 3000);
    },
    // handleClick(params) {
    //   const targetName = this.chartData[params.dataIndex].Key;
    //   this.$router.push(
    //     "/performance-report/visual-analysis/task?taskName=" + targetName
    //   );
    // }
  },
};
</script>
